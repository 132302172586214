html {
  box-sizing: border-box;
  font-size: 1.1rem;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

img {
  max-width: 100%;
  height: auto;
}

body {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    line-height: 1.4;
}

.container {
    max-width: 860px;
    margin: 0 auto;
    padding: 3em;
}

ul {
    padding-left: 1.2em;
}

p, ul {
    margin-top: 1.5rem;
}
p:first-child {
    margin-top: 0;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.logo {
    margin-bottom: 1em;
}

$diameter: 80px;
$border-width: 6px;
h1 {
    display: inline-block;
    border-radius: 50%;
    background: #f368e0;
    width: $diameter;
    height: $diameter;
    a {
        margin: ($border-width - ($border-width / 2px)) 0 0 ($border-width - ($border-width / 2px));
        width: $diameter - $border-width;
        height: $diameter - $border-width;
        border-radius: 50%;
        display: block;
        background: white;
        text-align: center;
        line-height: $diameter - $border-width;
        &:hover {
            color: white;
            background: none;
            text-decoration: none;
        }
    }
}

@mixin list($pos, $display) {
    li {
        display: $display;
        border: 0;
        margin: 0;
        padding: 0;
        padding-#{$pos}: 1em;
        margin-#{$pos}: 1em;
        border-#{$pos}: 1px solid #ddd;
        &:first-child {
            border-#{$pos}: 0;
            margin-#{$pos}: 0;
            padding-#{$pos}: 0;
        }
    }
}

ul {
    list-style-type: none;
    padding: 0;
    @include list(top, block);
    @media screen and (min-width: 670px) {
        @include list(left, inline-block);
    }
}

a {
    color: #f368e0;
}

.card-0 blockquote { background: #f368e0; }
.card-2 blockquote { background: #ff6b6b; }
.card-5 blockquote { background: #0abde3; }
.card-3 blockquote { background: #10ac84; }
.card-1 blockquote { background: #00d2d3; }
.card-6 blockquote { background: #54a0ff; }
.card-4 blockquote { background: #5f27cd; }

.card {
    color: white;
    padding: 0.5em;
    blockquote {
        margin: 0;
        padding: 1.5em;
        height: 100%;
    }
}

.main-content.blurred {
    filter: blur(5px);
}

.culture-noms {
    .logo-wrapper {
        .close {
            float: right;
            padding-right: 0.5em;
            color: rgb(34 47 62 / 0.5);
            font-size: 30px;
            line-height: 80px;
            &:hover {
                color: rgb(34 47 62 / 1);
                text-decoration: none;
            }
        }
        padding-left: 0.5em;
        .logo {
            margin-bottom: 0.5em;
        }
        h1 a {
            background: #576574;
            color: white;
            &:hover {
                background: none;
            }
        }
    }
    display: none;
    background: rgba(87, 101, 116, 0.8);
    position: absolute;
    top: 0;
    padding: 1em;
    .row {
        display: block;
        .card {
            flex: none;
            display: block;
        }
    }
    &.displayed {
        display: block;
    }
    @media screen and (min-width: 1100px) {
        .row {
            display: flex;
            .card {
                flex: 1;
            }
        }
    }
}

